






























import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import InsProductItem from '@/components/business/pc/product/InsProductItem.vue';
import { Promotion, Product } from '@/model/promotionResult';
@Component({ components: { InsProductItem } })
export default class EdMonterssori extends Vue {
  private promotion: Promotion = new Promotion();
  @Prop({ default: 4 }) column!: number;
  @Prop({ default: 100 }) w!: number;
  @Prop({ default: true }) border!: boolean;
  @Prop({ default: true }) top!: boolean;
  @Prop({ default: true }) button!: boolean;
  // private Desc: string='';
  private DescList: string[] = [];
  created () {
    this.$Api.promotion.getPromotion('Home', 2).then(result => {
      this.promotion = result.Promotion;
      if (this.promotion.PrmtProductList.length % this.column !== 0) {
        let count =
          this.column - (this.promotion.PrmtProductList.length % this.column);
        for (let i = 0; i < count; i++) {
          this.promotion.PrmtProductList.push(new Product(true));
        }
      }
    });
  }
  // @Watch('Desc')
  // onDescChange () {
  //   this.DescList = this.Desc.split(';');
  // }
}
